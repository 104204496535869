import Link from './link';
import React from 'react';
import { useBreakpoint } from 'gatsby-plugin-breakpoints';
import Asset from './asset';
import styles from './press.module.scss';
import theme from '../styles/theme.module.scss';

// import Swiper core and required components
import SwiperCore, { Navigation, Pagination, Autoplay, A11y } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/swiper.scss';
import 'swiper/components/navigation/navigation.scss';

// install Swiper components
SwiperCore.use([Navigation, Pagination, Autoplay, A11y]);

function Press(props) {
  const breakpoints = useBreakpoint();

  if (props.nodes.length > 1) {
    return (
      <div className={`${styles.root} ${theme.borderTop}`}>
        <Swiper
          className={`${theme.swiperWrapper}`}
          spaceBetween={0}
          slidesPerView={1}
          loop={true}
          speed={1000}
          autoplay={{ delay: 6000 }}
          noSwipingSelector={'a'}>
          {props.nodes &&
            props.nodes.map((node, key) => (
              <SwiperSlide key={key} className={`${theme.swiperSlide}`}>
                <div className={`${theme.gdcont} ${theme.gmcont}`}>
                  <div
                    className={`${styles.meta} ${theme.gds1} ${theme.gde3} ${theme.gmmain} ${theme.mB6}`}>
                    <h2 className={theme.h5_founders}>{node.title}</h2>
                    <p>{node.copy}</p>
                    {!breakpoints.md && (
                      <>
                        <Link className={`${theme.h5_founders} ${theme.text_link}`} to={node.url}>
                          Read More
                        </Link>
                      </>
                    )}
                  </div>
                  <div className={`${theme.gds4} ${theme.gde12} ${theme.gmmain}`}>
                    <blockquote className={`${styles.quote}`}>
                      <p className={theme.h2_tiempos}>{node.quoteText}</p>
                      <footer className={theme.h2_tiempos}>— {node.quoteAuthor}</footer>
                    </blockquote>
                    {breakpoints.md && (
                      <>
                        <Link
                          className={`${theme.h5_founders} ${theme.text_link} ${theme.mT6}`}
                          to={node.url}>
                          Read More
                        </Link>
                      </>
                    )}
                  </div>
                </div>
              </SwiperSlide>
            ))}
        </Swiper>
      </div>
    );
  } else if (props.nodes.length == 1) {
    return (
      <div className={`${styles.root} ${theme.borderTop}`}>
        {props.nodes &&
          props.nodes.map((node, key) => (
            <div className={`${theme.gdcont} ${theme.gmcont}`}>
              <div
                className={`${styles.meta} ${theme.gds1} ${theme.gde3} ${theme.gmmain} ${theme.mB6}`}>
                <h2 className={theme.h5_founders}>{node.title}</h2>
                <p>{node.copy}</p>
                {!breakpoints.md && (
                  <>
                    <Link className={`${theme.h5_founders} ${theme.text_link}`} to={node.url}>
                      Read More
                    </Link>
                  </>
                )}
              </div>
              <div className={`${theme.gds4} ${theme.gde12} ${theme.gmmain}`}>
                <blockquote className={`${styles.quote}`}>
                  <p className={theme.h2_tiempos}>{node.quoteText}</p>
                  <footer className={theme.h2_tiempos}>— {node.quoteAuthor}</footer>
                </blockquote>
                {breakpoints.md && (
                  <>
                    <Link
                      className={`${theme.h5_founders} ${theme.text_link} ${theme.mT6}`}
                      to={node.url}>
                      Read More
                    </Link>
                  </>
                )}
              </div>
            </div>
          ))}
      </div>
    );
  } else {
    return null;
  }
}

export default Press;
